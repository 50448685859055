<template>
	<div class="culture ql-editor" v-html="companyDetail.textDescription"></div>
</template>

<script setup>
	import { getPartDetail } from '@/api/index'
	import { ref, onMounted, getCurrentInstance } from 'vue'
	
	const { proxy } = getCurrentInstance(); 
	const companyDetail = ref({})//文化理念54
	
	onMounted(()=>{
		getPartDetail({ ifPage: 1, moduleId: proxy.partten.moduleCompanyCultrue }).then((res)=>{
			if(res.rows.length > 0){
				companyDetail.value = res.rows[0];
			}
		})
	})
</script>
<style lang="less">
  @import '@/assets/css/common';
</style>
<style lang="less" scoped>
.culture{
	font-size: 14px;padding: 0;
	/deep/ img{
		max-width: 100%;display: none;
	}
	/deep/ h1,h2,h3,h4{
		font-size: 20px;margin: 10px 0;
	}
	/deep/ p{
		line-height: 24px;
	}
}
</style>
